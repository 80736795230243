body {
  color: #000;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Open Sans, sans-serif;
}

h1 strong, h2 strong, h3 strong, h4 strong, h5 strong, h6 strong {
  font-weight: 800;
}

.text-blue {
  color: #0b76db;
}

img {
  width: auto;
  max-width: 100%;
  display: inline-block;
}

.join.smaller {
  padding: 13px 7%;
  font-size: 17px;
}

@media (width >= 768px) {
  .join.smaller {
    padding: 15px 7%;
    font-size: 19px;
  }
}

@media (width >= 991px) {
  .join.bigger {
    font-size: 34px;
  }
}

.join.outline {
  color: #000;
  background: none;
  border: 2px solid #000;
}

.join.outline:hover {
  color: #fff;
  background: #000;
}

.join.outline.white {
  color: #fff;
  border-color: #fff;
}

.join.outline.white:hover {
  color: #000;
  background: #fff;
}

.trailer-section {
  color: #fff;
  background: #00182d;
  padding: 40px 0 0;
}

@media (width >= 768px) {
  .trailer-section {
    padding: 50px 0 0;
  }
}

.trailer-section p {
  text-transform: uppercase;
  color: #525963;
  margin: 0 auto 15px;
  font-size: 14px;
}

@media (width >= 768px) {
  .trailer-section p {
    margin: 0 auto 20px;
    font-size: 16px;
  }
}

.trailer-section p img {
  vertical-align: bottom;
  height: 25px;
  margin: 0 5px 0 0;
}

@media (width >= 768px) {
  .trailer-section p img {
    height: 30px;
    margin: 0 7px 0 0;
  }
}

.trailer-section h1 {
  margin: 0 auto 25px;
  font: 400 19px / 1.2em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .trailer-section h1 {
    margin: 0 auto 35px;
    font-size: 25px;
  }
}

@media (width >= 991px) {
  .trailer-section h1 {
    margin: 0 auto 45px;
    font-size: 32px;
  }
}

.trailer-section h1 strong {
  font-size: 28px;
  line-height: 1.2em;
}

@media (width >= 768px) {
  .trailer-section h1 strong {
    font-size: 38px;
  }
}

@media (width >= 991px) {
  .trailer-section h1 strong {
    font-size: 48px;
  }
}

.trailer-section .ipad-phone {
  width: 100%;
  max-width: 330px;
  margin: 0 auto -10px;
  position: relative;
}

@media (width >= 768px) {
  .trailer-section .ipad-phone {
    max-width: 600px;
    margin: 0 auto -20px;
  }
}

@media (width >= 991px) {
  .trailer-section .ipad-phone {
    max-width: 800px;
    margin: 0 auto -25px;
  }
}

.trailer-section .ipad-phone .preview {
  width: 110px;
  margin: 0;
  position: absolute;
  top: 35%;
  left: 27%;
  transform: translate(-50%, -50%);
}

@media (width >= 768px) {
  .trailer-section .ipad-phone .preview {
    width: 130px;
    left: 31%;
  }
}

@media (width >= 991px) {
  .trailer-section .ipad-phone .preview {
    width: 140px;
    top: 37%;
    left: 32%;
  }
}

.trailer-section .ipad-phone i {
  text-align: center;
  text-indent: 5px;
  cursor: pointer;
  background: #f61a30;
  border: 3px solid #fff;
  border-radius: 50%;
  outline: none;
  width: 58px;
  height: 58px;
  font-size: 25px;
  line-height: 52px;
  position: absolute;
  top: 48%;
  left: 42%;
  transform: translate(-50%, -50%);
}

@media (width >= 768px) {
  .trailer-section .ipad-phone i {
    width: 78px;
    height: 78px;
    font-size: 35px;
    line-height: 72px;
  }
}

.trailer-section .ipad-phone i:hover {
  background: #f84b5c;
}

.download-pitch {
  background: #eff0f1;
  padding: 30px 15px 40px;
}

@media (width >= 768px) {
  .download-pitch {
    padding: 40px 0;
  }
}

.download-pitch p {
  width: 100%;
  max-width: 270px;
  margin: 0 auto;
  font: 400 14px / 1.6em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .download-pitch p {
    max-width: 100%;
    font-size: 17px;
  }
}

@media (width >= 991px) {
  .download-pitch p {
    font-size: 19px;
  }
}

.download-pitch p.small {
  font-size: 12px;
}

.download-pitch .download-badge img {
  width: 145px;
  margin: 20px 4px;
}

@media (width >= 768px) {
  .download-pitch .download-badge img {
    width: 160px;
    margin: 30px 15px;
  }
}

@media (width >= 991px) {
  .download-pitch .download-badge img {
    width: 180px;
  }
}

.learn-play-watch {
  padding: 40px 0;
}

@media (width >= 768px) {
  .learn-play-watch {
    padding: 80px 15px;
  }
}

@media (width >= 991px) {
  .learn-play-watch {
    padding: 115px 20px;
  }
}

.learn-play-watch .container {
  max-width: 770px;
}

@media (width >= 991px) {
  .learn-play-watch .container {
    max-width: 1040px;
  }
}

.learn-play-watch h1 {
  margin: 0 auto 10px;
  font: 800 30px / 1em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .learn-play-watch h1 {
    margin: 0 auto 15px;
    font-size: 50px;
  }
}

@media (width >= 991px) {
  .learn-play-watch h1 {
    font-size: 64px;
  }
}

.learn-play-watch h2 {
  margin: 0 auto;
  font: 400 15px / 1.5em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .learn-play-watch h2 {
    font-size: 22px;
  }
}

@media (width >= 991px) {
  .learn-play-watch h2 {
    font-size: 26px;
  }
}

.learn-play-watch img {
  width: 100%;
  max-width: 330px;
  margin: 25px auto;
}

@media (width >= 768px) {
  .learn-play-watch img {
    max-width: 630px;
    margin: 35px auto;
  }
}

@media (width >= 991px) {
  .learn-play-watch img {
    max-width: 760px;
    margin: 45px auto 35px;
  }
}

.learn-play-watch .topic-wrap {
  margin: 0 auto 60px;
}

@media (width >= 768px) {
  .learn-play-watch .topic-wrap {
    margin: 0 auto 110px;
  }
}

.learn-play-watch .topic-wrap:last-child {
  margin: 0 auto;
}

@media (width >= 768px) {
  .learn-play-watch .topic-wrap.play img {
    max-width: 660px;
  }
}

@media (width >= 991px) {
  .learn-play-watch .topic-wrap.play img {
    max-width: 860px;
  }
}

@media (width >= 768px) {
  .learn-play-watch .topic-wrap.connection img {
    max-width: 720px;
  }
}

@media (width >= 991px) {
  .learn-play-watch .topic-wrap.connection img, .learn-play-watch .topic-wrap.watch img {
    max-width: 940px;
  }
}

.final-pitch {
  background: #eff0f1;
  padding: 40px 15px 50px;
}

@media (width >= 768px) {
  .final-pitch {
    padding: 60px 0 80px;
  }
}

@media (width >= 991px) {
  .final-pitch {
    padding: 75px 0 95px;
  }
}

.final-pitch .container {
  max-width: 1100px;
}

.final-pitch h1 {
  margin: 0 auto 5px;
  font: 800 23px / 1.3em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .final-pitch h1 {
    margin: 0 auto 45px;
    font-size: 31px;
  }
}

@media (width >= 991px) {
  .final-pitch h1 {
    font-size: 39px;
  }
}

.final-pitch h1 sup {
  font-size: 50%;
  top: -.8em;
}

.final-pitch p {
  width: 100%;
  max-width: 270px;
  margin: 0 auto;
  font: 400 14px / 1.6em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .final-pitch p {
    max-width: 100%;
    font-size: 17px;
  }
}

@media (width >= 991px) {
  .final-pitch p {
    font-size: 19px;
  }
}

.final-pitch p a {
  color: inherit;
}

.final-pitch p.small {
  font-size: 12px;
}

.final-pitch .download-badge img {
  width: 150px;
  margin: 0 4px 20px;
}

@media (width >= 768px) {
  .final-pitch .download-badge img {
    width: 160px;
    margin: 0 15px 30px;
  }
}

@media (width >= 991px) {
  .final-pitch .download-badge img {
    width: 180px;
  }
}

.final-pitch .option-wrap {
  border: 0 solid #fff;
  border-bottom-width: 2px;
  padding: 30px 0;
}

@media (width >= 768px) {
  .final-pitch .option-wrap {
    border-width: 0 2px 0 0;
    padding: 27px 0;
  }
}

.final-pitch .option-wrap:last-child {
  border: none;
  padding-bottom: 0;
}

.final-pitch .option-wrap p {
  color: #666;
  margin: 0 auto 27px;
  font: 400 14px / 1em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .final-pitch .option-wrap p {
    font-size: 16px;
  }
}

.final-pitch .option-wrap h4 {
  margin: 0 auto;
  font: 400 16px / 1.4em Open Sans, sans-serif;
}

@media (width >= 768px) {
  .final-pitch .option-wrap h4 {
    font-size: 17px;
  }
}

@media (width >= 991px) {
  .final-pitch .option-wrap h4 {
    font-size: 19px;
  }
}

.final-pitch .option-wrap .download-badge img {
  width: 145px;
  margin: 30px 3px 0;
}

@media (width >= 768px) {
  .final-pitch .option-wrap .download-badge img {
    width: 160px;
  }
}

@media (width >= 991px) {
  .final-pitch .option-wrap .download-badge img {
    width: 180px;
    margin: 30px 7px 0;
  }
}

.final-pitch .option-wrap .join {
  margin: 30px 0 0;
  padding: 16px 30px;
  font-size: 16px;
}

@media (width >= 991px) {
  .final-pitch .option-wrap .join {
    padding: 17px 30px;
    font-size: 20px;
  }
}

.reveal-overlay {
  z-index: 2147483001;
  background-color: #000c;
  display: none;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.reveal-overlay:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  text-rendering: auto;
  content: "";
  color: #fff;
  z-index: 1;
  opacity: .8;
  text-align: center;
  outline: none;
  width: 35px;
  margin: 0;
  font-family: "Font Awesome 6 Pro";
  font-size: 35px;
  font-style: normal;
  font-weight: 900;
  line-height: 1em;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

@media (width >= 768px) {
  .reveal-overlay:after {
    width: 50px;
    font-size: 50px;
    top: 7px;
    right: 7px;
  }
}

.reveal-overlay .reveal {
  z-index: 1006;
  backface-visibility: hidden;
  width: 90%;
  max-width: 75rem;
  height: inherit;
  background-color: #fefefe;
  border: none;
  border-radius: 7px;
  outline: none;
  min-height: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  display: none;
  position: relative;
  top: 100px;
  overflow-y: auto;
}

@media (width >= 768px) {
  .reveal-overlay .reveal {
    margin: 0 auto;
    left: auto;
    right: auto;
  }
}

.reveal-overlay .reveal .flex-video {
  margin: 0 auto;
}

.reveal-overlay .reveal p {
  margin: 5px auto;
}

.reveal-overlay .reveal .join {
  margin: 10px auto;
}

.reveal-overlay .reveal.annual-pitch {
  max-width: 740px;
  padding: 15px 20px;
}

@media (width >= 768px) {
  .reveal-overlay .reveal.annual-pitch {
    padding: 15px 30px;
  }
}

@media (width >= 991px) {
  .reveal-overlay .reveal.annual-pitch {
    padding: 15px 50px;
  }
}

.reveal-overlay .reveal.annual-pitch h3 {
  line-height: 1.3em;
}

.reveal-overlay .reveal.annual-pitch .flex-video {
  border-radius: 7px;
  margin: 20px auto;
}

.reveal-overlay .reveal.annual-pitch .join {
  margin: 0 auto;
}

@media (width >= 768px) {
  .reveal-overlay .reveal.annual-pitch .join {
    width: 75%;
  }
}

.reveal-overlay .reveal.annual-pitch p {
  font-size: 13px;
}

.reveal-overlay .reveal.coach-wrap {
  text-align: center;
  border-radius: 10px;
  max-width: 330px;
  overflow: visible;
}

@media (width >= 768px) {
  .reveal-overlay .reveal.coach-wrap {
    max-width: 450px;
  }
}

.reveal-overlay .reveal.coach-wrap .top-image {
  background: #333 top / cover no-repeat;
  border-radius: 10px 10px 0 0;
  padding-bottom: 75%;
  position: relative;
}

.reveal-overlay .reveal.coach-wrap .bottom-details {
  -webkit-user-select: none;
  user-select: none;
  padding: 15px;
}

@media (width >= 768px) {
  .reveal-overlay .reveal.coach-wrap .bottom-details {
    padding: 20px;
  }
}

.reveal-overlay .reveal.coach-wrap .bottom-details h3 {
  text-transform: uppercase;
}

.reveal-overlay .reveal.coach-wrap .bottom-details p {
  margin: 0 auto 3px;
}

@media (width >= 768px) {
  .reveal-overlay .reveal.coach-wrap .bottom-details p {
    margin: 7px auto;
  }
}

.reveal-overlay .reveal.coach-wrap .bottom-details p strong {
  font-weight: 900;
}

.reveal-overlay .reveal.coach-wrap .bottom-details p .text-red {
  text-transform: uppercase;
}

.reveal-overlay .reveal.coach-wrap .bottom-details .bio {
  text-align: left;
  margin: 0 auto;
  line-height: 1.3em;
}

@media (width >= 768px) {
  .reveal-overlay .reveal.coach-wrap .bottom-details .bio {
    line-height: 1.5em;
  }
}

.reveal-overlay .reveal.coach-wrap .next-prev {
  color: #fff;
  cursor: pointer;
  font-size: 40px;
  position: absolute;
  top: 270px;
}

@media (width >= 768px) {
  .reveal-overlay .reveal.coach-wrap .next-prev {
    font-size: 70px;
  }
}

.reveal-overlay .reveal.coach-wrap .next-prev.fa-angle-left {
  left: -21px;
}

@media (width >= 768px) {
  .reveal-overlay .reveal.coach-wrap .next-prev.fa-angle-left {
    left: -50px;
  }
}

.reveal-overlay .reveal.coach-wrap .next-prev.fa-angle-right {
  right: -21px;
}

@media (width >= 768px) {
  .reveal-overlay .reveal.coach-wrap .next-prev.fa-angle-right {
    right: -50px;
  }
}
/*# sourceMappingURL=sales-app-pianote.css.map */
